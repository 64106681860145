import { CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { PagedListOfDiscountProgramProviderAdminModel } from "services/borbalo-main.service";
import { useNavigate } from "react-router-dom";
import { adminDiscountProgramService } from "services/adminDiscountProgram";
import GeneralStateComponent from "components/GeneralStateComponent";

const DiscountProgramProviders = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const { data, isLoading, run } = useAsync<
    PagedListOfDiscountProgramProviderAdminModel,
    any
  >();

  useEffect(() => {
    run(adminDiscountProgramService.getDiscountProgramProviders());
  }, []);

  const dataInfo =
    data?.entities?.map(item => [
      item.name,
      item.email,
      item.mainDiscountAmount,
      item.brandsCategory,
      <GeneralStateComponent
        marginBottom={1}
        total={item.activeBrandsCount}
        totalWithModeration={item.onModerationBrandsCount}
        totalDeactivated={item.deactivatedBrandsCount}
        count={item.onModerationBrandsCount}
      />,
    ]) ?? [];

  const tableData = [
    ["Name", "Email", "Discount", "Category", "Brand statuses"],
    ...dataInfo,
  ];

  const onPress = (index: number) => {
    const entity = data?.entities?.[index];
    if (entity?.id) {
      navigate(`/discount-program-providers/${entity.id}`);
    }
  };

  const onPressDisabled = (index: number) => {
    const entity = data?.entities?.[index];

    return (
      !entity?.activeBrandsCount &&
      !entity?.onModerationBrandsCount &&
      !entity?.deactivatedBrandsCount
    );
  };

  return (
    <>
      <div className="row">
        <Typography variant="h3" mb={1.6}>
          {title}
          {isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
      </div>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable
        tableData={tableData}
        onPress={onPress}
        onPressDisabled={onPressDisabled}
        buttonText={"Show Brands"}
      />
    </>
  );
};

export default DiscountProgramProviders;
